import React, { useEffect, useMemo, useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Converter } from 'opencc-js'
import usePlacesAutocomplete, {
  GeocodeResult,
  getGeocode,
  getLatLng,
  Suggestion,
} from 'use-places-autocomplete'

import { LocationMarker } from '@/modules/assets/icon/LocationMarkerIcon'
import { MainButton } from '@/modules/components/MainButton'
import { useToastWrapper } from '@/modules/hooks/useToastWrapper'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { RegistrationRequest } from '@/oas/talent/api'

const converter = Converter({ from: 'tw', to: 'jp' })

interface Props {
  talentInformation: RegistrationRequest
  setTalentInformation: (talentInformation: RegistrationRequest) => void
  onBackStep: () => void
  onNextStep: () => void
}

const EMPTY_VALUES = ['', null, undefined]
const GOOGLE_API_ERROR = 'エラーが発生しました。再度お試しください。'

/**
 * 求職者の希望勤務地を選択するコンポーネント
 */
export const DesiredWorkLocation: React.FC<Props> = ({
  talentInformation,
  setTalentInformation,
  onBackStep,
  onNextStep,
}: Props) => {
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const toastWrapper = useToastWrapper()
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: 'ja',
      region: 'jp',
      types: [
        'administrative_area_level_1',
        'locality',
        'train_station',
        'subway_station',
        'transit_station',
      ], // Only search for localities and train stations
      componentRestrictions: { country: 'jp' }, // Only search within Japan
      offset: 0,
    },
    debounce: 300,
    cache: 24 * 60 * 60, // 24Hours
  })

  const constructValue = (facilityName: string, address?: string) => {
    if (EMPTY_VALUES.includes(facilityName)) {
      return ''
    }
    if (EMPTY_VALUES.includes(address)) {
      return facilityName
    }
    return `${facilityName} - ${address}`
  }

  useEffect(() => {
    // 別のステップから戻ってきたときにInputに値をセットする
    setValue(constructValue(talentInformation.facility_name, talentInformation.address), false)
  }, [])

  const isFinished = useMemo(() => {
    return (
      !EMPTY_VALUES.includes(talentInformation.facility_name) &&
      !EMPTY_VALUES.includes(talentInformation.place_id) &&
      talentInformation.lat > 0 &&
      talentInformation.lng > 0 &&
      !EMPTY_VALUES.includes(talentInformation.prefecture_name)
    )
  }, [talentInformation])

  const selectLocation = async (selected: Suggestion, mainText: string) => {
    if (isBusy) return

    setIsBusy(true)

    // inputのvalueを選択した場所に変更
    setValue(constructValue(mainText, selected.structured_formatting.secondary_text), false)

    try {
      // geo情報を取得
      const results: GeocodeResult[] = await getGeocode({
        placeId: selected.place_id,
        language: 'ja',
      })
      const result: GeocodeResult = results[0]
      const { lat, lng } = getLatLng(result)
      // 都道府県名を取得
      const prefecture = result.address_components.find(
        (component: google.maps.GeocoderAddressComponent) =>
          component.types.includes('administrative_area_level_1'), // administrative_area_level_1は都道府県のtype
      )?.long_name

      // talentInformationを更新
      setTalentInformation({
        ...talentInformation,
        facility_name: mainText,
        address: selected.structured_formatting.secondary_text,
        place_id: selected.place_id,
        lat: lat,
        lng: lng,
        prefecture_name: prefecture || '',
      })
    } catch (e) {
      console.error(e)
      toastWrapper.error(GOOGLE_API_ERROR)
    } finally {
      // 選択肢をクリア
      clearSuggestions()
      setIsBusy(false)
    }
  }

  const clearDesiredWorkLocation = () => {
    if (isFinished) {
      setTalentInformation({
        ...talentInformation,
        facility_name: '',
        address: '',
        place_id: '',
        lat: 0,
        lng: 0,
        prefecture_name: '',
      })
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Inputの値が更新されたら、選択した場所をStateから削除する
    clearDesiredWorkLocation()

    setValue(e.target.value)
  }

  const clearInput = () => {
    clearDesiredWorkLocation()
    setValue('')
  }

  const renderSuggestions = () => {
    return (
      <Box
        border="1px solid #E2E8F0"
        borderRadius="6px"
        bg="white"
        p={2}
        position="absolute"
        top="56px"
        zIndex={1}
      >
        {data.map((suggestion: Suggestion) => {
          // 繁体字を日本語に変換
          // Why: 繁体字でサジェストされる場合があるため（東京都　→ 東京都澀谷區）
          const convertedMaintext = converter(suggestion.structured_formatting.main_text)
          return (
            <Box
              key={suggestion.place_id}
              as="p"
              py={4}
              px={3}
              fontSize="sm"
              bg="white"
              _notLast={{ borderBottom: '1px solid #E2E8F0' }}
              onClick={() => selectLocation(suggestion, convertedMaintext)}
            >
              <Box as="span" fontWeight={700}>
                {convertedMaintext}
              </Box>
              {suggestion.structured_formatting.secondary_text && (
                <Box as="span"> - {suggestion.structured_formatting.secondary_text}</Box>
              )}
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <VStack as="form" w="100%" spacing="24px" noValidate>
      <FormControl
        isInvalid={status !== '' && status !== 'OK'} // 空とOK以外はエラー
        _last={{ mb: '24px' }}
        position="relative"
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none" mt="1">
            <LocationMarker category="search" color="#1A202C" />
          </InputLeftElement>
          <Input
            size="lg"
            placeholder="駅名・バス停・市区町村など"
            pl={9}
            _placeholder={{ color: '#A0AEC0' }}
            value={value}
            onChange={handleInput}
            disabled={!ready || isBusy}
            data-gtm-click-item="registration_agent_work_location_input"
          />
          {value && (
            <InputRightElement>
              <CloseIcon boxSize={3} mt={2} onClick={clearInput} />
            </InputRightElement>
          )}
        </InputGroup>
        {status === 'OK' && renderSuggestions()}
        <FormErrorMessage>{GOOGLE_API_ERROR}</FormErrorMessage>
        <FormHelperText fontSize="12px" mb="8px">
          入力内容に応じて表示される候補地から、希望勤務地を選択してください。
        </FormHelperText>

        <Alert
          status="info"
          borderRadius="4px"
          p="8px"
          alignItems="flex-start"
          bg="brand.blue-right"
        >
          <AlertIcon mr="8px" />
          <Flex fontSize="14px" lineHeight="24px" flexDirection="column">
            <Text>入力内容の一例</Text>
            <Text>・東新宿駅</Text>
            <Text>・博多バスターミナル</Text>
            <Text>・千葉市花見川区</Text>
          </Flex>
        </Alert>
      </FormControl>
      <Flex w="100%" gap="8px">
        <MainButton mode="secondary" borderColor="border.primary" h="48px" p="10px 32px">
          <Text
            fontSize="14px"
            fontWeight={700}
            lineHeight="20px"
            color="text.primary"
            onClick={onBackStep}
            data-gtm-click-item="registration_agent_back_button"
          >
            戻る
          </Text>
        </MainButton>
        <MainButton
          mode="primary"
          type="submit"
          borderColor="border.primary"
          w="100%"
          h="48px"
          p="10px 32px"
          isDisabled={!isFinished}
          onClick={onNextStep}
          data-gtm-click-item={'registration_agent_step_work_location_button' as GTMClickItemName}
        >
          <Text fontSize="14px" fontWeight={700} lineHeight="20px">
            次へ
          </Text>
        </MainButton>
      </Flex>
    </VStack>
  )
}
