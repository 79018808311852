import React, { useMemo } from 'react'
import { Button, ButtonProps, Flex, Image, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import SmsIcon from '../../assets/icon/SmsIcon.svg'
import { getViewName } from '../../utils/gtm/gaEventActions'
import { GTMClickItemName } from '../../utils/gtm/types/common'

interface Props extends ButtonProps {
  'data-gtm-click-item'?: GTMClickItemName
}
const commonStyle = {
  fontSize: '16px',
  padding: '10px 24px',
  borderRadius: '100px',
  height: '54px',
  backgroundColor: 'white',
  color: 'text.primary',
  border: '1px solid var(--border-border-primary, #E2E8F0)',
}

export const SmsButton: React.FC<Props> = ({ ...props }) => {
  const router = useRouter()
  const gtmViewName = getViewName(router.asPath)
  const clickItemName = useMemo(() => {
    if (gtmViewName === 'registration_agent') {
      return 'registration_agent_step_sms_submit_button' as GTMClickItemName
    } else {
      return `${gtmViewName}_sms_auth_button`
    }
  }, [gtmViewName])
  return (
    <Button
      // modeごとの初期スタイルを定義
      {...commonStyle}
      // 他はそのまま全て渡す。重複した場合上書きされる。
      {...props}
      data-gtm-click-item={clickItemName}
    >
      <Flex w="100%" alignItems="center" justifyContent="center" gap="12px">
        <Image src={SmsIcon.src} />
        <Text w="100%" lineHeight="24px" fontWeight={700}>
          電話番号（SMS）で続ける
        </Text>
      </Flex>
    </Button>
  )
}
