import { useEffect, useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Badge, Box, Button, Flex, Text, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { startTalentRegistrationViaLineAuthentication } from '@/api/lineAuthApi'
import { DesiredWorkLocation } from '@/components/auth/registration/DesiredWorkLocation'
import { ErrorDialog } from '@/components/parts/ErrorDialog'
import { LogoAndTitleElm } from '@/components/parts/LogoAndTitleElm'
import { registrationFormAgentToValues } from '@/const/registrationFormValues'
import { useAuth } from '@/hooks/useAuth'
import { useDialog } from '@/hooks/useDialog'
import { useInquiry } from '@/hooks/useInquiry'
import { MainButton } from '@/modules/components/MainButton'
import { useToastWrapper } from '@/modules/hooks/useToastWrapper'
import { useWindowSize } from '@/modules/hooks/useWindowSize'
import { isProd } from '@/modules/utils/env'
import { getViewName } from '@/modules/utils/gtm/gaEventActions'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { RegistrationRequest } from '@/oas/talent/api'
import { checkNotSendSMS } from '@/utils/validation/checkNumber'

import { BasicInformation, BasicInformationFormValues } from './BasicInformation'
import { ContactInformation } from './ContactInformation'
import { SMSConfirmElm } from './SMSConfirmElm'

// このコンポーネントが提供する「戻る」ボタンを表示させないステップ
// 1, 5, 6ページが対象
const DONT_DISPLAY_BACK_STEP = [0, 4, 5]

export const OptionButton = ({
  label,
  value,
  onClick,
  gtmClickItemName,
  isMobile,
  isDisabled,
}: {
  label: string
  value: string | number
  onClick: (value: string | number) => void
  gtmClickItemName: string
  isMobile: boolean
  isDisabled?: boolean
}) => {
  return (
    <Button
      variant="outline"
      borderColor="text.link"
      fontWeight="bold"
      fontSize="16px"
      lineHeight="24px"
      justifyContent="space-between"
      w="100%"
      p="10px 16px 10px 24px"
      h="48px"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
      onClick={() => onClick(value)}
      opacity={isDisabled ? 0.2 : 1}
      _hover={isMobile ? { bg: 'none' } : { bg: 'gray.50' }}
      data-gtm-click-item={gtmClickItemName as GTMClickItemName}
    >
      {label}
      <ChevronRightIcon w="20px" h="20px" color="text.link" />
    </Button>
  )
}

/**
 * 求職者の新規登録用フォーム群をまとめ、ページ内ページを切り替えて表示してゆくためのコンポーネント
 * フォーム入力値の管理、認証ステップへの遷移を責務とする
 * 入力値はこのコンポーネントで発行しているuseStateで管理（talentInformation）する
 * ただし、個別のフォームコンポーネントでは適宜、ReactHookForm + Zodで入力値検査・管理を行うこともある
 */
export const RegistrationForAgentPageWrapper: React.FC = () => {
  const { error } = useToastWrapper()
  const router = useRouter()
  const useViewName = getViewName(router.asPath)
  const { errorDialogProps, openDialogWithTitleAndMessage } = useDialog()
  const { isPcView } = useWindowSize()

  const [currentStep, setCurrentStep] = useState(0)
  const [talentInformation, setTalentInformation] = useState<RegistrationRequest>({
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    birthday: '',
    gender_master_id: 0,
    email: '',
    phone_number: '',
    final_education_master_id: 0,
    current_employment_type_master_id: 0,
    desired_job_start_periods_master_id: 0,
    has_driver_license: false,
    facility_name: '',
    address: '',
    place_id: '',
    lat: 0,
    lng: 0,
    prefecture_name: '',
  })
  const [canResend, setCanResend] = useState(true)
  const [isDuplicate, setIsDuplicate] = useState(false)

  const {
    isConfirm,
    setIsConfirm,
    confirmFunc,
    submit: onSetPhoneNumber,
    resendVerificationCode,
    registration,
    isVerifyLoading,
    setIsVerifyLoading,
    confirmError,
    checkContact,
    sessionInitialize,
  } = useAuth()
  const { getInquiryOfJobIdToCookie } = useInquiry()

  const onOptionButtonClick = (key: string, value: string | number) => {
    setTalentInformation({
      ...talentInformation,
      [key]: value,
    })
    setCurrentStep(currentStep + 1)
  }

  const onSubmitBasicInformation = (data: BasicInformationFormValues) => {
    setTalentInformation({ ...talentInformation, ...data })
    setCurrentStep(currentStep + 1)
  }

  const onBack = () => setCurrentStep(currentStep - 1)
  const onNext = () => setCurrentStep(currentStep + 1)

  const onLineSubmit = async (talent?: RegistrationRequest) => {
    if (!talent) return
    const isCheck = await checkContact(talent.email, talent.phone_number)
    if (isCheck) {
      setIsDuplicate(true)
      return
    }
    // LINE新規登録セッションスタート用エンドポイントへのPOSTリクエストを行う
    const response = await startTalentRegistrationViaLineAuthentication(talent)
    return response.data.line_login_url
  }

  const checkMobile = async (phoneNumber: string, email: string) => {
    setIsDuplicate(false)
    setIsVerifyLoading(true)
    // 数字のみ入力されているかチェック
    if (!/^\d+$/.test(phoneNumber)) {
      error('電話番号は数字のみで入力してください。')
      setIsVerifyLoading(false)
      return
    }
    // 入力された電話番号の形式チェック
    if (checkNotSendSMS(phoneNumber, isProd)) {
      openDialogWithTitleAndMessage(
        'LINEで続けるをご利用ください',
        'ご入力いただいた電話番号ではSMSでの認証ができません。',
      )
      setIsVerifyLoading(false)
      return
    }

    // すでに登録されているかどうかを確認する
    const isCheck = await checkContact(email, phoneNumber)
    if (isCheck) {
      setIsDuplicate(true)
      setIsVerifyLoading(false)
      return
    }
    onSetPhoneNumber(phoneNumber)
  }

  const onSmsSubmit = async (value: string) => {
    setIsVerifyLoading(true)
    confirmFunc
      ?.confirm(value)
      .then(async (res) => {
        const idToken = await res.user.getIdToken()
        const jobId = getInquiryOfJobIdToCookie()
        const sourceProduct = jobId ? 'search' : 'scout'
        await registration(talentInformation, idToken, sourceProduct).then((v) => {
          if (!v.isSuccess) {
            openDialogWithTitleAndMessage(
              '会員登録済みのアカウントです',
              '会員登録が過去にされているアカウントのため、ログインからご利用ください。',
            )
          }
          if (v.isSuccess) {
            router.push('/registration/scout')
          }
          setIsVerifyLoading(false)
        })
      })
      .catch((res) => {
        confirmError(res)
        setIsVerifyLoading(false)
      })
  }

  const resendSMS = () => {
    if (canResend) {
      if (resendVerificationCode) {
        setCanResend(false)
        resendVerificationCode(talentInformation.phone_number)
        setTimeout(() => {
          setCanResend(true)
        }, 60000)
      } else {
        error('認証コードの再送に失敗しました。画面を更新して再度お試しください。')
      }
    } else {
      openDialogWithTitleAndMessage(
        'まだ再送信はできません',
        'SMS認証コードの再送信には、60秒以上の間隔が必要です。少し時間を置いてからお試しください。',
      )
    }
  }

  useEffect(() => {
    sessionInitialize() // Why: Laravel側のsession_idを予め、排出しておくため
    if (router.query.error === 'conflict') {
      openDialogWithTitleAndMessage(
        '会員登録済みのアカウントです',
        '会員登録が過去にされているアカウントのため、ログインからご利用ください。',
      )
    }

    // クエリでステップ1の回答があればその回答をstateに保存し、画面はステップ2から開始させる
    if (router.query.step1) {
      const { step1 } = router.query
      const step1Value = registrationFormAgentToValues[0].options.find(
        (option) => option.value === Number(step1),
      )
      if (step1Value) {
        setTalentInformation({
          ...talentInformation,
          [registrationFormAgentToValues[0].key]: step1Value.value,
        })
        setCurrentStep(1)
      }
    }
  }, [])

  const generateTag = (currentStep: number) => {
    // ステップ数と内容を紐づける
    // WHY: 初動でステップ数の命名で計測してしまい、後から命名を変更すると計測値が変わってしまうため
    switch (currentStep) {
      case 0:
        // 就業開始までの期間ボタンはステップ0とする
        return `${useViewName}_step_0_button`
      case 1:
        // 就業状況ボタンはステップ2とする
        return `${useViewName}_step_2_button`
      case 2:
        // 最終学歴ボタンはステップ3とする
        return `${useViewName}_step_3_button`
      case 3:
        // 自動車免許ボタンはステップ4とする
        return `${useViewName}_step_4_button`
      default:
        return ''
    }
  }

  const simpleSelect = (currentStep: number) => {
    return (
      <VStack w="100%" spacing="8px">
        {registrationFormAgentToValues[currentStep].options.map((option) => {
          // currentStepが1（就業状況）でvalueが7（学生）の場合はdisabledにしてクリックされたらダイアログを出す
          if (currentStep === 1 && option.value === 7) {
            return (
              <OptionButton
                key={`${option.label}-${option.value}`}
                label="学生"
                value={7}
                onClick={() =>
                  openDialogWithTitleAndMessage(
                    '学生はサポート対象外です',
                    '申し訳ありませんが、現在学生の方のサポートは対象外となっております。',
                  )
                }
                gtmClickItemName={generateTag(currentStep)}
                isMobile={!isPcView}
                isDisabled
              />
            )
          }
          return (
            <OptionButton
              key={`${option.label}-${option.value}`}
              label={option.label}
              value={option.value}
              onClick={() =>
                onOptionButtonClick(registrationFormAgentToValues[currentStep].key, option.value)
              }
              gtmClickItemName={generateTag(currentStep)}
              isMobile={!isPcView}
            />
          )
        })}
      </VStack>
    )
  }

  const renderForm = (currentStep: number) => {
    switch (currentStep) {
      case 0:
      case 1:
      case 2:
      case 3:
        return simpleSelect(currentStep)
      case 4:
        return (
          <DesiredWorkLocation
            talentInformation={talentInformation}
            setTalentInformation={setTalentInformation}
            onBackStep={onBack}
            onNextStep={onNext}
          />
        )
      case 5:
        return (
          <BasicInformation
            talentInformation={talentInformation}
            submit={onSubmitBasicInformation}
            onBackStep={onBack}
          />
        )
      case 6:
        return (
          <ContactInformation
            isVerifyLoading={isVerifyLoading}
            isDuplicate={isDuplicate}
            talentInformation={talentInformation}
            setTalentInformation={setTalentInformation}
            checkMobile={checkMobile}
            lineSubmit={(talent) => onLineSubmit(talent)}
          />
        )
      default:
    }
  }

  return (
    <Flex
      maxWidth="640px"
      m="0 auto"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      backgroundColor="white"
      minHeight="100vh"
    >
      <LogoAndTitleElm title="会員登録" />
      <Box w="100%" p="0 24px 40px">
        {isConfirm ? (
          <SMSConfirmElm
            isVerifyLoading={isVerifyLoading}
            phoneNumber={talentInformation.phone_number}
            lineSubmit={(talent) => onLineSubmit(talent)}
            onSmsSubmit={onSmsSubmit}
            setIsConfirm={setIsConfirm}
            resendSMS={resendSMS}
            talentInformation={talentInformation}
          />
        ) : (
          <VStack
            w="100%"
            p="16px"
            border="1px solid var(--border-border-primary, #E2E8F0)"
            borderRadius="8px"
            spacing="24px"
          >
            <Flex w="100%" justifyContent="space-between" alignItems="flex-start" gap="16px">
              <Text fontSize="16px" fontWeight={700} lineHeight="150%" whiteSpace="pre-wrap">
                {registrationFormAgentToValues[currentStep].title}
              </Text>
              <Badge p="4px" borderRadius="4px" color="text.secondary">{`${currentStep + 1} / ${
                registrationFormAgentToValues.length
              }`}</Badge>
            </Flex>
            {renderForm(currentStep)}
            {!DONT_DISPLAY_BACK_STEP.includes(currentStep) && (
              <MainButton
                mode="secondary"
                borderColor="border.primary"
                h="40px"
                p="10px 24px"
                fontSize="14px"
                fontWeight={700}
                lineHeight="20px"
                color="text.primary"
                onClick={onBack}
                data-gtm-click-item={'registration_agent_back_button' as GTMClickItemName}
              >
                戻る
              </MainButton>
            )}
          </VStack>
        )}
      </Box>
      {currentStep === 0 && (
        <VStack
          w="100%"
          spacing="16px"
          p="40px 24px"
          borderTop="1px solid var(--border-border-primary, #E2E8F0);"
        >
          <Text fontSize="11px" lineHeight="150%" color="text.primary">
            すでにアカウントをお持ちですか？
          </Text>
          <MainButton mode="secondary" borderColor="border.primary" h="40px" p="10px 24px">
            <Text
              fontSize="14px"
              fontWeight={700}
              lineHeight="20px"
              color="text.primary"
              onClick={() => router.push('/login')}
              data-gtm-click-item={'registration_agent_login_button' as GTMClickItemName}
            >
              ログイン
            </Text>
          </MainButton>
        </VStack>
      )}
      <ErrorDialog {...errorDialogProps} />
    </Flex>
  )
}
